import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";

import { Typography } from "components/Wrappers/Wrappers";
import { DataGrid } from "@material-ui/data-grid";

import { verifyRut } from "utils/rutValidations.js";
import {
  dbGet,
  dbPost,
  getInscriptionsByPatientRut,
} from "utils/DBFetchers.js";
import logo from "images/logoPagesRS.jpg";

import TextField from "@material-ui/core/TextField";

import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import RutField from "components/CustomFields/RutField";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

const blankPatientForm = {
  rut: "",
  rutVerifier: "",
  email: "",
  rutMessage: "",
  isRutValid: false,
};

function AmountSelect(props) {
  return (
    <TextField
      name="a"
      type="number"
      value={props.value}
      onChange={props.onChange}
      InputProps={{
        inputProps: {
          min: 0,
          max: props.max,
        },
      }}
      error={+props.value < 0 || props.max < +props.value}
    />
  );
}

const generateRow = (data) => {
  return {
    id: data.id,
    presentationName: data.presentation?.name,
    remainingUnits: data.remaining_units,
    discount: data.vademecum?.discount,
    amount: {
      id: data.id,
      availableAmount: data.remaining_units,
      amountToBuy: "0",
    },
  };
};

const generatePresentationToPost = (data) => {
  return {
    inscription_id: data.id,
    total_units: parseInt(data.amount.amountToBuy),
  };
};

export default function NewSalePage() {
  var classes = useStyles();
  const [patientForm, setPatientForm] = useState(blankPatientForm);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [discontinuedRSPopUp, setDiscontinuedRSPopUp] = useState(true)

  const updateAmountToBuy = (x, value, id) => {
    if (x.id === id) {
      x.amount.amountToBuy = value;
    }
    return x;
  };

  const onChangeAmountToBuy = (event, id) => {
    setAvailableProducts(
      availableProducts.map((x) =>
        updateAmountToBuy(x, event.target.value, id),
      ),
    );
  };

  // Colums with the products to buy
  const availableProductsColumns = [
    {
      field: "id",
      hide: true,
      type: "number",
    },
    {
      field: "presentationName",
      headerName: "Producto",
      width: 360,
      valueFormatter: (params) => params.row?.presentation?.name,
    },
    {
      field: "discount",
      headerName: "Descuento (%)",
      width: 155,
      align: "center",
    },
    {
      field: "remainingUnits",
      headerName: "Unidades Disponibles para Venta",
      width: 270,
      align: "center",
    },
    {
      field: "amount",
      headerName: "Unidades en esta Venta",
      width: 210,
      align: "center",
      renderCell: (params) => (
        <AmountSelect
          max={params.value.availableAmount}
          value={params.value.amountToBuy}
          onChange={(event) => onChangeAmountToBuy(event, params.value.id)}
        />
      ),
    },
  ];

  const totalWidth = availableProductsColumns
    .map((x) => (x.width ? x.width : 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue);

  // 1- Load patient email from rut
  async function setPatientData(rut, rutValidation) {
    if (!rutValidation) {
      setPatientForm({
        ...patientForm,
        email: "",
        rutMessage: "RUT invalido",
        isRutValid: false,
      });
      return;
    }
    if (rutValidation && !patientForm.rutVerifier) {
      setPatientForm({
        ...patientForm,
        email: "",
        rutMessage: "Ingrese el dígito verificador en el campo respectivo.",
        isRutValid: false,
      });
      return;
    }
    dbGet(`patients/${rut}`).then((data) => {
      if (Object.keys(data).length === 0) {
        setPatientForm({
          ...patientForm,
          email: "",
          rutMessage: `Paciente no registrado`,
          isRutValid: false,
        });
        return;
      }
      setPatientForm({
        ...patientForm,
        email: data.email,
        rutMessage: "",
        isRutValid: true,
      });
      return;
    });
  }

  // 2- Load patient inscriptions for table
  const updateAvailableProducts = (rut) => {
    var r = [];
    getInscriptionsByPatientRut(rut).then((data) => {
      data = data.sort((a, b) => {
        if (a.presentation.name > b.presentation.name) return 1;
        if (a.presentation.name < b.presentation.name) return -1;
        // a must be equal to b
        return 0;
      });
      if (Object.keys(data).length !== 0) {
        r = data;
      }
      return setAvailableProducts(r.map((x) => generateRow(x)));
    });
  };

  // Do 1- and 2-
  const fetchPatient = () => {
    var rut = patientForm.rut + patientForm.rutVerifier;

    const rutValidation = verifyRut(rut);

    setPatientData(rut, rutValidation);
    if (rutValidation) updateAvailableProducts(rut);
  };

  const onRutChangePatient = (e) => {
    setPatientForm({ ...patientForm, rut: e.target.value.toUpperCase() });
  };
  const onRutChangeVerifier = (e) => {
    setPatientForm({
      ...patientForm,
      rutVerifier: e.target.value.toUpperCase(),
    });
  };

  React.useEffect(() => {
    if (!patientForm.rut && !patientForm.rutVerifier) return; // no fetch on page load
    fetchPatient();
  }, [patientForm.rut, patientForm.rutVerifier]);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const invalidAmounts = availableProducts.filter(
      (x) => x.amount.availableAmount < x.amount.amountToBuy,
    );
    if (invalidAmounts.length !== 0) {
      snackbarOpen(
        "error",
        "No se pueden agregar más unidades de las disponibles",
      );
      setIsLoading(false);
      return;
    }
    if (
      !patientForm.rut ||
      !patientForm.isRutValid ||
      availableProducts.length === 0
    ) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder generar la venta.",
      );
      setIsLoading(false);
      return;
    }

    const presentationToPost = availableProducts
      .map((x) => generatePresentationToPost(x))
      .filter((x) => x.total_units > 0);
    if (presentationToPost.length === 0) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder generar la venta.",
      );
      setIsLoading(false);
      return;
    }

    dbPost("sales", presentationToPost)
      .then((data) => {
        snackbarOpen("success", "Venta creada exitosamente");
        handleCleanFilters();
        setIsLoading(false);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al crear la venta",
        );
        setIsLoading(false);
        return;
      });
  };

  const handleCleanFilters = () => {
    setPatientForm(blankPatientForm);
  };

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateFormPatientOnProgramIdChange = () => {
    fetchPatient();
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!patientForm.rut && !patientForm.rutVerifier) return; // no fetch on page load
    updateFormPatientOnProgramIdChange();
  }, [programId]);

  return (
    <>
       {programId === "1" &&
          <Dialog open={discontinuedRSPopUp} onClose={() => setDiscontinuedRSPopUp(false)}>
            <DialogTitle>{`Cupones tradicionales RS descontinuados`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Cupones Tradicionales no están más en vigencia. Por favor, utilice Quemables
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.popupDivCenter2}>
              <Button
                onClick={() => setDiscontinuedRSPopUp(false)}
                variant="contained"
                color="primary"
                className={classes.popupButtonSpacing}
              >
                Entendido
              </Button>
            </DialogActions>
          </Dialog>
        }
      
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title="Nueva Venta"
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="on">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del paciente
              </Typography>
              <RutField
                labelName="RUT * (sin guión)"
                required={true}
                id="patientRut"
                id2="patientRutVerifier"
                value={patientForm.rut}
                divClassType={classes.smallItems2}
                divClassType2={classes.generalPurposeItems2}
                fieldClassType={classes.smallFieldValidation2}
                fieldClassType2={classes.smallFieldValidation3}
                onChange={onRutChangePatient}
                helperText={patientForm.rutMessage}
                error={!patientForm.isRutValid}
                value2={patientForm.rutVerifier}
                onChange2={onRutChangeVerifier}
              ></RutField>
              <Field
                labelName="Email"
                id="email"
                value={patientForm.email}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                disabled={true}
              ></Field>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Productos en esta venta
              </Typography>
              <div
                className={classes.generalPurposeItems}
                style={{ height: 350, width: totalWidth + 10 }}
              >
                <DataGrid
                  rows={patientForm.isRutValid ? availableProducts : []}
                  columns={availableProductsColumns}
                  disableColumnMenu={true}
                  pageSize={25}
                  checkboxSelection={false}
                  disableSelectionOnClick={true}
                  localeText={dataGridTexts}
                  showToolbar={true}
                  disableColumnSelector={true}
                  hideFooter={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.alignRight}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.submitLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={programId === "1"}
                  >
                    Guardar
                  </Button>
                )}
            </Grid>
            <Grid item xs={6} className={classes.alignLeft}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCleanFilters}
                >
                  Limpiar
                </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
